export const clientRoutes = ["/", "reservas", "horarios"];

export const modalitiesConstant = [
  "Beach Tennis",
  "Futvôlei",
  "Vôlei",
  "Futebol",
];

export const daysOfWeek = [
  "Domingo",
  "Segunda Feira",
  "Terça Feira",
  "Quarta Feira",
  "Quinta Feira",
  "Sexta Feira",
  "Sábado",
];
